import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPropertyFeatureGroups(ctx, queryParams) {
      console.log(queryParams);
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-feature-group', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAllPropertyFeatureGroups(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-feature-group')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyFeatureGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/property-feature-group/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePropertyFeatureGroup(ctx, { propertyFeatureGroupData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/property-feature-group/${id}`, propertyFeatureGroupData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePropertyFeatureGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/property-feature-group/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPropertyFeatureGroup(ctx, propertyFeatureGroupData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/property-feature-group', propertyFeatureGroupData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
