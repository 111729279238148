import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function usePropertyFeatureGroupList() {
  // Use toast
  const toast = useToast();

  const refPropertyFeatureGroupListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: 'الرقم التسلسلي',
      formatter: title,
      sortable: true,
    },
    // {
    //   key: 'name',
    //   label: 'إسم المجموعة',
    //   formatter: title,
    //   sortable: true,
    // },
    {
      key: 'display_name',
      label: 'إسم العرض للمجموعة',
      formatter: title,
      sortable: true,
    },
    {
      key: 'parent',
      label: 'المجموعة الرئيسية',
      formatter: title,
      sortable: true,
    },
    {
      key: 'total_features',
      label: 'المجموعة الخصائص',
      sortable: true,
    },
    { key: 'actions', label: 'الإجراءات' },
  ];
  const perPage = ref(10);
  const totalPropertyFeatureGroup = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const dataMeta = computed(() => {
    const localItemsCount = refPropertyFeatureGroupListTable.value ? refPropertyFeatureGroupListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPropertyFeatureGroup.value,
    };
  });

  const refetchData = () => {
    refPropertyFeatureGroupListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchPropertyFeatureGroups = (ctx, callback) => {
    store
      .dispatch('propertyFeatureGroup/fetchPropertyFeatureGroups', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { data: propertyFeatureGroups, total } = response.data;

        callback(propertyFeatureGroups);
        totalPropertyFeatureGroup.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ عند جلب القائمة',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPropertyFeatureGroups,
    tableColumns,
    perPage,
    currentPage,
    totalPropertyFeatureGroup,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPropertyFeatureGroupListTable,
    refetchData,

    // Extra Filters
  };
}
